.buttonContained {
  box-sizing: border-box;
  padding: 6px 14px 6px 14px;
  background: #ffb52e;
  border: 2px solid #0b0a0b;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01rem;
  box-shadow: 4px 6px 0 #0b0a0b;
  border-radius: 6px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  z-index: 1;
  min-width: 142px;
  min-height: 42px;
  width: 100%;
  white-space: pre-wrap;

  text-align: center;
  text-decoration: none;
  &:hover {
    background-color: #ef8030;
  }
  &:active {
    box-shadow: 2px 4px 0px #0b0a0b;
  }

  & a {
    text-decoration: none;
    color: #0b0a0b;
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  @media screen and (min-width: 640px) {
    width: unset;
    white-space: unset;
  }
}

.secondary {
  width: 100%;
  background: #c683f6;
  font-size: 20px;
}
