@import "./../../core.scss";

.location {
  padding: 0 20px;
  margin-top: 6px;

  &__title {
    font-style: normal;
    font-size: 66px;
    line-height: 76px;
    color: $yellow;
    text-align: center;
    margin-top: 94px;

    @include title();
  }

  &__subtitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: $primary-dark;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.image__container {
  max-width: 1350px;
  margin: 42px auto 0;
  display: flex;
  gap: 20px;
  justify-content: start;

  &__image {
    border: 1px solid $black;
    object-fit: cover;
    height: 520px;
    width: 322px;
  }
}

@media screen and (max-width: 1440px) {
  .location {
    padding: 0 16px;

    &__title {
      margin-top: 46px;
    }
  }

  .image__container {
    display: flex;
    gap: 24px;
    margin-top: 42px;
    overflow-x: auto;
    justify-content: start;
    width: 100%;

    img {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 320px;
        height: 520px;
      }
    }
  }

  .image__container::-webkit-scrollbar {
    display: none;
  }

  .image__container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@media screen and (max-width: 1024px) {
  .image__container {
    display: flex;
    gap: 24px;
    margin-top: 42px;
    overflow-x: auto;
    justify-content: start;
    width: 100%;

    img {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 30%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .location {
    &__title {
      font-size: 36px;
      line-height: 48px;
      font-family: "Contrail One";
      font-style: normal;
    }
  }
  .image__container {
    display: flex;
    gap: 24px;
    margin-top: 36px;
    width: 100%;
    padding-right: 20px;

    img {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 225px;
        height: 365px;
      }
    }
  }
}
