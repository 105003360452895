@mixin flex($direction, $wrap, $justify, $align) {
  display: flex;
  flex-flow: $direction $wrap;
  justify-content: $justify;
  align-items: $align;
}

@mixin scale_from_to($from, $to) {
  @media screen and (max-width: $from) and (min-width: $to) {
    @content;
  }
}

@function remConverter($pixelSize) {
  $result: $pixelSize / 16;
  @return $result + rem;
}

@mixin font($family: 'Open Sans', $size: 16px, $lineHeight: 16px, $weight: 400, $style: normal) {
  font-family: $family;
  font-size: $size;
  line-height: $lineHeight;
  font-weight: $weight;
  font-style: $style;
}

@mixin title() {
  font-family: 'Contrail One', cursive, Arial, sans-serif;
  letter-spacing: -.02em;
  position: relative;
  text-shadow: 4px 6px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 1px 1px #0d0d0d, 3px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d, 1px 0px #0d0d0d;
  text-transform: uppercase;
  width: fit-content;
  font-weight: 400;
}

@mixin titleSecondary() {
  font-family: 'Contrail One', cursive, Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  width: fit-content;
  letter-spacing: -0.02em;
  text-shadow: 2px 3px 0 $black;
  -webkit-text-stroke: 1px $black;
  position: relative;

  @media screen and (min-width: 1200px) {
    text-shadow: 4px 6px 0 $black;
    -webkit-text-stroke: 2px $black;
  }
}
