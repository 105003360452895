@import "./../../core.scss";

.blueBanner {
  max-width: 1351px;
  margin: 62px auto;
  padding: 42px 0;
  background: $green;
  border: 2px solid $black;
  border-radius: 12px;
  box-shadow: 8px 8px 0 $black;

  &__text {
    font-weight: 700;
    font-size: 46px;
    line-height: 62px;
    color: $primary-dark;
    max-width: 1140px;
    text-align: center;
    margin: 0 auto;

    &__purple {
      display: inline-block;
      text-align: center;
      margin: 10px auto 0;
      font-size: 66px;
      line-height: 70px;
      color: $purple;
      @include title();
    }
  }
  &__ticketsBox {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .blueBanner {
    margin: 24px auto;
    &__text {
      font-size: 26px;
      line-height: 40px;
      &__purple {
        font-size: 36px;
        line-height: 48px;

        @include titleSecondary();
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .blueBanner {
    margin: 24px auto;
    padding: 16px;
    &__text__purple {
      display: block;
    }
    &__button {
      width: 100%;
    }
  }
}
