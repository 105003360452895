@import "./mixins.scss";
@import "./variables.scss";
@import "./sizes.scss";

* {
  margin: 0;
  padding: 0;
}

#root {
  //min-height: 100vh;
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
  //min-width: 100vw;
  // overflow-x: hidden;
}

html {
  scroll-padding-top: 80px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

body {
  background-color: $purple;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  position: relative;
  height: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
  background-color: $purple;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05),
    inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.page-container {
  padding: 0 16px;
  @media screen and (min-width: 767px) {
    margin: 0 auto;
    max-width: calc(1342px + 15px + 15px);
    padding: 0 15px;
  }
}

.page {
  height: 100%;
  @include scale_from_to($smallDesktop, $min-size) {
    margin-top: 0;
    overflow-x: hidden;
  }
  @include scale_from_to(1400px, $min-size) {
    overflow-x: hidden;
  }
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.container {
  max-width: $desktop;
  border: 1px solid $black;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  padding: 0 50px;
}

.promo-video {
  margin: 62px auto 0;
  height: fit-content;
  width: 100%;
  background: #0d0d0d;

  display: flex;
  align-items: center;
  justify-content: center;

  &__fullscreen {
    right: 0;
    bottom: 0;
    width: 100%;
    height: auto;
  }

  @include scale_from_to($tablet, $min-size) {
    &__fullscreen {
      width: inherit;
      min-height: auto;
    }
  }
}

.button {
  display: inline-block;
  box-sizing: border-box;
  color: $yellow;
  background-color: $red;
  text-transform: uppercase;
  white-space: nowrap;

  min-width: 142px;
  min-height: 42px;

  border-radius: 30px;
  border: 2px solid $yellow;
  box-shadow: 6px 6px 0 #000000;

  padding: 6px 14px;

  font-style: 400;

  z-index: 12;
  font-size: 16px;
  line-height: 24px;
  font-family: "DM Sans", serif;
  font-weight: 600;
  cursor: pointer;

  &:disabled,
  &[disabled] {
    background: gray;

    &:hover {
      background-color: gray;
      color: $yellow;
      font-style: normal;
    }
  }

  &:active {
    background: $primary-red;
    font-style: italic;
    color: $white;
  }

  @include scale_from_to($tablet, $min-size) {
    width: -webkit-fill-available;
    font-size: 28px;
    line-height: 36px;
  }
}

.button:hover {
  background: $orange;
  box-shadow: 6px 8px 0 #0b0a0b;
  font-style: normal;
}

.button:active {
  background: $orange;
  color: #0b0a0b;
  box-shadow: 2px 4px 0 #0b0a0b;
  font-style: normal;
}

.button.button--primary {
  background: $red;
  font-style: italic;
  color: $white;
}

.comic-text {
  font-size: 66px;
  line-height: 82px;
  font-family: "Contrail One", cursive;
  letter-spacing: 2px;
  text-shadow: 0 1px 0 rgb(13, 13, 13), 0px -1px 0 rgb(13, 13, 13),
    1px 0px 0 rgb(13, 13, 13), -1px 0px 0 rgb(13, 13, 13),
    1px 1px 0 rgb(13, 13, 13), 1px -1px 0 rgb(13, 13, 13),
    -1px 1px 0 rgb(13, 13, 13), -1px -1px 0 rgb(13, 13, 13),
    0px 2px 0 rgb(13, 13, 13), 0px -2px 0 rgb(13, 13, 13),
    2px 0px 0 rgb(13, 13, 13), -2px 0px 0 rgb(13, 13, 13),
    2px 2px 0 rgb(13, 13, 13), 2px -2px 0 rgb(13, 13, 13),
    -2px 2px 0 rgb(13, 13, 13), -2px -2px 0 rgb(13, 13, 13),
    3px 3px 1px rgba(0, 0, 0, 1);
  color: $yellow;

  position: relative;
  z-index: 2;

  &--header::before {
    content: "";
    background-image: url("./../images/header-red-spot.svg");
    background-repeat: no-repeat;
    width: 532px;
    height: 130px;
    display: block;

    position: absolute;
    z-index: -1;
    top: -30px;
    left: -20px;
  }

  &--banner::before {
    content: "";
    background-image: url("./../images/banner-red-spot.svg");
    background-repeat: no-repeat;
    width: 400px;
    height: 165px;
    display: block;

    position: absolute;
    z-index: -1;
    top: -50px;
    left: -20px;
  }

  &--speakers::before {
    content: "";
    background-image: url("./../images/speakers-red-spot.svg");
    background-repeat: no-repeat;
    width: 713px;
    height: 147px;
    display: block;

    position: absolute;
    z-index: -1;
    top: -50px;
    left: -20px;
  }

  @include scale_from_to($smallDesktop, $tablet) {
    &--speakers::before {
      left: -30px;
    }
  }
  @include scale_from_to($tablet, $min-size) {
    letter-spacing: 0;
    font-size: 40px;
    line-height: 45px;
    text-shadow: 4px 4px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 1px -1px #0d0d0d,
      -1px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d,
      1px 0px #0d0d0d;
    &--header::before {
      top: 15%;
      left: 0;
      background-image: url("./../images/Vector-6.svg");
      width: 301px;
      height: 67px;
    }
    &--banner::before {
      top: -10px;
      width: 164px;
      height: 67px;
      background-image: url("./../images/banner-red-spot-small.svg");
    }
    &--speakers {
      margin-bottom: 10px;

      &::before {
        background-image: url("./../images/speakers-red-spot-small.svg");
        top: -30px;
        left: 0;
      }
    }
  }
}

.high-speakers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}
