@import "./../../core.scss";

.subscribe {
    margin: -10px auto 0;
    padding: 62px 16px;
    background: $black;
    overflow-x: hidden;

    &__fieldset {
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
    }

    &__title {
        font-weight: 400;
        font-size: 36px;
        line-height: 48px;
        margin: 0 0 6px;
        color: $yellow;
        font-family: 'Contrail One', cursive, Arial, sans-serif;
        text-transform: uppercase;

        text-shadow: 0 4px 0 $primary-dark, 0px -1px 0 $primary-dark, 4px 0px 0 $primary-dark, -1px 0px 0 $primary-dark, 4px 4px 0 $primary-dark, 1px -1px 0 $primary-dark, -1px 1px 0 $primary-dark, -1px -1px 0 $primary-dark, 0px 6px 0 $primary-dark, 0px -2px 0 $primary-dark, 2px 0px 0 $primary-dark, -2px 0px 0 $primary-dark, 6px 6px 0 $primary-dark, 6px -2px 0 $primary-dark, -2px 2px 0 $primary-dark, -2px -2px 0 $primary-dark, 3px 3px 1px black;
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 22px;
    }

    &__input {
        width: 100%;
        height: 50px;
        border: none;
        outline: none;
        border-bottom: 2px solid $yellow;
        color: $yellow;
        font-size: 16px;
        line-height: 12px;
        background: $primary-dark;

        &:active,
        &:focus { background: $primary-dark; }

        &:-webkit-autofill,
        &:-webkit-autofill:active,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-text-fill-color: #FFC239;
            -webkit-box-shadow: 0 0 0 1000px $primary-dark inset;
            transition: background-color 5000s ease-in-out 0s;
        }

        &::placeholder { color: rgba(255, 194, 57, .6); }
    }

    &__button {
        background: $yellow;
        border: 2px solid $black;
        border-radius: 6px;
        text-transform: uppercase;
        cursor: pointer;
        box-shadow: 4px 6px 0 $black;
        padding: 8px 24px;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        width: 100%;
        min-width: 284px;
        color: $black;

        &:hover {
            background: $orange;
            box-shadow: 6px 8px 0px $black;
        }
        &:active {
            box-shadow: 2px 4px 0px $black;
        }
    }


    &__notification {
        color: $yellow;
        font-size: 22px;
        line-height: 29px;
        font-weight: 400;
        margin-top: 20px;
        min-height: 30px;
    }

    &__success {
        color: $yellow;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }

    &__error-field {
        visibility: visible;
        line-height: 24px;
        margin: 3px auto 0;
    }

    &__error {
        color: red;
        font-size: 16px;
        line-height: 24px;
    }

    @media screen and (min-width: 640px) {
        padding: 42px 16px;

        &__title {
            font-size: 36px;
            line-height: 48px;
            margin: 0 0 24px;
            text-align: center;
        }

        &__input {
            width: 374px;
        }

        &__button {
            width: unset;
            min-width: unset;
        }

        &__wrap {
            flex-direction: row;
            gap: 22px;
        }

        &__error-field {
            width: 530px;
        }
    }

    @media screen and (min-width: 1200px) {
        padding: 62px 16px;

        &__title {
            font-size: 66px;
            line-height: 72px;
        }
    }
}
