@import "./../../core.scss";

.persons {
  padding: 66px 0;
  max-width: $desktop;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;

  @include scale_from_to($smallDesktop, $tablet) {
    padding: 80px 30px;
  }

  &__index {
    &__title {
      font-weight: 700;
      font-size: 26px;
      line-height: 40px;
      color: $black;
      margin-bottom: 32px;

      @media screen and (min-width: 1200px) {
        font-size: 46px;
        line-height: 63px;
      }

      &__yellow {
        text-align: center;
        display: block;
        margin-right: 12px;
        font-size: 36px;
        line-height: 48px;
        color: $yellow;
        @include title();

        @media screen and (min-width: 640px) {
          display: unset;
        }

        @media screen and (min-width: 1200px) {
          font-size: 66px;
          line-height: 70px;
        }
      }
    }
  }

  &__image {
    width: 20px;
    height: 24px;
    display: block;
    margin-left: 5px;
    fill: $primary-dark;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    height: min-content;
    text-decoration: none;
    gap: 12px;
    max-width: 1350px;
    width: 100%;
    margin: 32px auto 0;
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 4rem 2rem;
    max-width: 1351px;
    margin: 0 auto;
  }
  &__link {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    text-decoration: none;
    color: $primary-dark;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: 0;
      background: transparent;
      transition: width 0.7s ease, background-color 0.5s ease;
    }

    &:hover:after {
      width: 100%;
      background: $black;
    }
  }
  .person {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__link {
      position: relative;
    }

    &__remotely {
      background-color: #1DBC84;
      color: #0B0A0B;
      border: 1px solid #0B0A0B;
      position: absolute;
      width: 168px;
      text-align: center;
      text-decoration: none;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      text-transform: uppercase;
      bottom: 24px;
      left: 50%;
      transform: rotate(-3deg) translateX(-50%);
    }

    &__image {
      border: 1px solid $black;
      width: 210px;
      height: 210px;

      background: transparent;
      @media screen and (min-width: 1920px) {
        width: 250px;
        height: 250px;
      }
    }
    & .block__title {
      margin: 10px 0 4px;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
    }

    & .block__text, .block__title {
      max-width: 225px;
    }
  }
  @include scale_from_to($smallDesktop, $tablet) {
    padding: 80px 30px;
    &__wrap {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include scale_from_to($tablet, $min-size) {
    padding: 26px 0px;
    margin-bottom: 80px;
    &__wrap { grid-template-columns: 1fr; align-items: center; justify-items: center; }
    .banner {
      &-wrap {
        flex-direction: column;
      }
    }
    &__wrap {
      &::after {
        top: -137px;
      }
    }
    .person {
      align-items: center;
      & .block__title,
      & .block__text {
        text-align: center;
      }
    }
  }
}
