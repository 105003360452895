.swiper-block {
  max-width: 1356px;
  margin: 0 auto;
  padding: 42px 15px;

  .swiper {
    padding-bottom: 40px;
    width: 100%;
    height: 100%;

    @media (min-width: 769px) {
      --swiper-width: calc(calc(calc(100vw - 100%) / 2) + 100%);

      min-width: var(--swiper-width);
    }
  }

  .swiper__video {
    height: 420px;
    height: fit-content;
    margin: 0 auto;
  }

  .swiper__description {
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    text-align: left;

    margin-top: 12px;
    color: $primary-dark;
    padding-bottom: 12px;
  }

  .video-responsive {
    height: fit-content;
    max-height: 420px;
    min-height: 420px;
    margin: 0 auto;
  }

  @media screen and (min-width: 767px) {
    .video-responsive {
      width: 736px;

      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      min-height: auto;
    }
  }

  .video-responsive iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    //height: 100%;
    border: 0;
    clip-path: inset(-1px -1px);
    height: 420px;
  }

  .swiper-video-placeholder {
    position: relative;
    width: 736px;
    height: 420px;
    border: 4px solid #0b0a0b;
    box-sizing: border-box;
  }

  .swiper-video-placeholder::before {
    transform: translate(-50%, -50%);
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    content: "";
    width: 98px;
    height: 98px;
    background-image: url("./../images/videoplay.svg");
    background-size: 98px 98px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;

    align-items: flex-start;
    flex-direction: column;

    &:last-child {
      margin-right: 20px;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    width: fit-content;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 48px;
    background-repeat: no-repeat;
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-image: url("./../images/arrowhover.svg") !important;
  }

  .swiper-button-prev {
    background-image: url("./../images/arrownext.svg") !important;
    transform: rotate(180deg);
    left: calc(48% - 48px - 24px);
    position: absolute;
    top: 96%;
  }

  .swiper-button-next {
    background-image: url("./../images/arrownext.svg") !important;
    left: 48%;
    top: 90%;
    position: absolute;
    margin-top: 6px;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: "";
  }

  @include scale_from_to($tablet, $min-size) {
    padding: 0 0 50px;

    .swiper__description {
      font-size: 22px;
      padding: 0 15px;
    }

    .swiper {
      padding-bottom: 0;
    }

    .swiper-slide {
      margin-bottom: 50px;
    }

    .swiper-video {
      width: 100%;
      min-width: 100vw;
    }
  }

  @media screen and (min-width: 1600px) {
    //max-width: 1500px;
    margin: 0 auto;
  }
}

.article-swiper {
  padding: 0 50px;
  max-width: $desktop;
  margin: 0 auto;

  .block {
    width: 100%;
    margin: 0;

    @include scale_from_to(1024px, $tablet) {
      display: flex;
      align-items: center;
      padding-bottom: 40px;
    }

    @media screen and (min-width: 1024px) {
      margin: 0 10px;
    }
  }

  @include scale_from_to(2000px, 1700px) {
    .block__image {
      width: 370px;
    }
  }

  @include scale_from_to(1300px, 1024px) {
    padding: 0 30px;

    .block__image {
      width: 100%;
      height: 248px;
    }
  }

  @include scale_from_to($tablet, $min-size) {
    padding: 50px 0;
  }
}
