$primary-dark: #0D0D0D;
$secondary-dark: #151515;
$third-dark: #181818;
$fourth-dark: #1B1C1D;
$black: #000000;
$primary-red: #B92F29;
$primary-blue: #4FADE4;
$primary-grey: #696969;
$white: #ffffff;
$primary-green: #10A427;
$secondary-red: #D40900;

$primary-purple: #C683F6;
$primary-black: #0B0A0B;

$purple: #c683f6;
$yellow: #ffb52e;
$orange: #ef8030;
$red: #f33a3e;
$black: #0b0a0b;
$green: #5DBEAD;
