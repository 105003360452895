@import "./../../core.scss";

.conference-location {
  max-width: 1351px;
  margin: 42px auto 68px auto;

  &__title {
    font-style: normal;
    font-size: 66px;
    line-height: 76px;
    color: $yellow;
    -webkit-text-stroke: 1px #0A0A0C;
    @include title();
  }

  &__subtitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #0A0A0C;
    cursor: pointer;
  }

  &__description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    margin-top: 24px;
    color: #0A0A0C;
  }

}

@media screen and (max-width: 1440px) {
  .conference-location {
    &__title {
      font-size: 36px;
      line-height: 48px;
      text-shadow: 2px 4px 0 $black;
    }

    &__description {
      font-size: 20px;
      line-height: 32px;
    }
  }
}

