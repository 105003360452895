@import "./../../core.scss";

.plans {
  max-width: $desktop;
  padding: 40px 0;
  margin: 0 auto;

  &__title {
    margin-bottom: 36px;
    font-weight: 700;
    font-size: 46px;
    line-height: 68px;
  }

  &__title__yellow {
    color: $yellow;
    font-size: 68px;
    line-height: 70px;
    position: relative;
    white-space: nowrap;
    width: 100%;

    @include title();
  }

  &__blocks {
    display: flex;
    gap: 32px;
    justify-content: center;

    &__block {
      max-width: 660px;
      width: 50%;

      &__title {
        font-weight: 700;
        font-size: 26px;
        line-height: 40px;
        margin: 12px 0 4px;
      }

      &__img {
        width: 100%;
        border: 1px solid $black;
        height: 420px;
        object-fit: cover;
      }

      &__link {
        margin-top: 12px;
        display: flex;
        gap: 8px;
        flex-direction: row;
        text-decoration: none;
        align-items: center;
        color: $black;
        &__text {
          &::after {
            content: "";
            display: block;
            height: 1px;
            width: 0;
            background: transparent;
            transition: width 0.7s ease, background-color 0.5s ease;
          }

          &:hover:after {
            width: 100%;
            background: $black;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .plans {
    &__title {
      margin-bottom: 34px;
      font-size: 26px;
      line-height: 40px;
      &__yellow {
        font-size: 36px;
        line-height: 48px;
        //-webkit-text-stroke: 1px $black;
        &::after {
          top: -1px;
          //-webkit-text-stroke: 1px  $black ;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .plans {
    padding: 0 0 24px;

    &__blocks {
      flex-direction: column;
      &__block {
        width: 100%;

        &__img {
          height: 208px;
        }
      }
    }
  }
}
