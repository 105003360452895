@import "./../../core.scss";

.multi-toggle-container {
  padding: 4px 8px 8px;
  line-height: 48px;
  margin: 16px auto 16px;

  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  //justify-content: space-between;
  width: fit-content;

  position: static;
  transition: all 0.1s ease-in-out;
  //box-shadow: 2px 4px 0px $black;

  background: $purple;
  /* HB23/Black */

  border: 1px solid $black;
  /* Shadow small_2-4 */

  box-shadow: 2px 4px 0 $black;
  border-radius: 12px;

  &.fixed {
    position: sticky;
    top: 100px;
    background: $purple;
    z-index: 10;
    //max-width: 820px;
    //width: 100%;
    transition: all 0.1s ease-in-out;
  }

  .active-layer {
    position: absolute;
    transition: 0.4s ease-in-out;
    margin: 3px;
    margin-left: calc(66.66% + 3px);
    border-radius: 5px;
    height: calc(100% - 6px);
    background-color: white;
    width: calc(100% / 3 - 6px);
  }
}

.multi-toggle-box {
  width: 100%;
  position: static;

  &.fixed {
    position: sticky;
    top: 90px;
    background: linear-gradient(
      180deg,
      $purple 26.88%,
      rgba(198, 131, 246, 0) 80.3%
    );
    z-index: 1;
  }
}

.select {
  display: none;
}

.multi-toggle-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(13, 13, 13, 0.8);
  padding: 8px 14px;
  z-index: 2;
  cursor: pointer;
  position: static;
  text-decoration: none;
  box-sizing: border-box;
  width: fit-content;
  transition: all 0.4s ease;

  background: $purple;
  border: 1px solid transparent;
  /* Shadow small_2-4 */

  box-shadow: 2px 4px 0 transparent;
  border-radius: 6px;

  @media (min-width: 768px) {
    &:hover {
      //background: transparent;
      //border: 2px solid $green;
      //box-shadow: 2px 1px 0 $black;
      //border-radius: 12px;

      background: $purple;
      /* HB23/Black */

      border: 1px solid $black;
      /* Shadow small_2-4 */

      box-shadow: 2px 4px 0 $black;
    }
  }

  &.active {
    cursor: pointer;
    background-color: $green;

    border: 1px solid $black;

    box-shadow: 2px 4px 0 $black;
    border-radius: 6px;

    top: 0;
    z-index: 1;
    transition: all 0.4s ease;

    @media (min-width: 768px) {
      &:hover {
        background: $green;
        color: $black;
      }
    }
  }
}

@include scale_from_to(1023px, $min-size) {
  .multi-toggle-container {
    display: none;
  }
  .select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 23px;
    position: relative;
    margin: 39px auto 40px;

    color: #2c2c2c;
    &-option {
      border: 1px solid #2c2c2c;
      box-sizing: border-box;
      border-radius: 6px;
      min-width: 360px;
      padding: 10px 18px;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::after {
        content: "";
        background: url("./../../assets/images/chevron-down.svg");
        width: 16px;
        height: 16px;
        display: block;
      }
    }
    &-options {
      position: absolute;
      z-index: 100;
      display: flex;
      flex-direction: column;
      gap: 8px;
      top: 44px;
      min-width: 360px;
      box-sizing: border-box;
      padding: 16px;
      margin-top: 10px;
      background: $purple;
      /* HB23/Black */
      border: 1px solid $black;
      border-radius: 6px;

      visibility: hidden;

      opacity: 0;

      transition: transform 100ms ease-in-out, opacity 100ms ease-in-out,
        visibility 0ms linear 100ms;

      pointer-events: none;
      -webkit-overflow-scrolling: touch;
      transition-duration: 75ms;

      animation-duration: 75ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in;

      animation-name: content_leaving;
    }
    &-options.visible {
      visibility: visible;
      /* In Edge (pre chromium), a stacking context is formed for opacity less then 1, and then its removed for 1.
           It causes a rendering flicker that is visible when css transition is applied. */
      opacity: 0.9999;

      transition-delay: 0ms;

      pointer-events: auto;

      animation-name: content_entering;
    }

    &-item {
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      cursor: pointer;

      color: rgba(13, 13, 13, 0.8);
      padding: 8px 14px;
      //border-bottom: 1px solid rgba(13, 13, 13, 0.12);

      /* HB23/green */

      background: $green;
      /* HB23/Black */

      border: 1px solid $black;
      /* Shadow small_2-4 */

      box-shadow: 2px 4px 0 $black;
      border-radius: 6px;

      &:last-child {
        border-bottom: none;
      }
    }
  }
  .multi-toggle-container {
    flex-direction: column;
    border: none;
  }
}

@keyframes content_entering {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes content_leaving {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}

.stickyToggle {
  &.fixed {
    top: 0;
  }
}

.topDistance {
  padding-top: 5px;
}
