@import './../../core.scss';

.tc-wrapper {
    max-width: 1098px;
    margin: 0 auto 98px;
    padding: 0 16px;

    &__append {
        padding: 50px 0 0;
        &__link {
            display: block;
            margin-bottom: 8px;
            text-decoration: underline;
            cursor: pointer;
        }
        &__text {
            margin: 20px 0 0;
        }
        &__text, &__link {
            font-size: 18px;
            line-height: 23px;
            color: $black;
        }
    }

    &__header{
        margin-top: 50px;
        position: relative;
        text-align: center;
        height: 125px;

        &__title {
            text-align: center;
            font-size: remConverter(66);
            line-height: 76px;

            color: $yellow;
            font-family: 'Contrail One',cursive,Arial,sans-serif;
            font-weight: 700;
            letter-spacing: -.02em;
            position: relative;
            text-shadow: 4px 6px 0 #000;
            text-transform: uppercase;
            white-space: nowrap;
            width: 100%;

            &::after {
                content: attr(title);
                left: 0;
                position: absolute;
                text-shadow: 3px -1px 0 $primary-dark,3px -1px $primary-dark,-1px 4px $primary-dark,0 -1px $primary-dark,-1px 0 $primary-dark,0 1px $primary-dark,1px 0 $primary-dark;
                width: 100%
            }
        }

        p {
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
            font-size: 20px;
            line-height: 32px;
        }
    }

    &__container {
        &__block{
            border-bottom: 2px solid $primary-dark;
        }
        &__title {
            font-size: 30px;
            line-height: 39px;
            font-weight: 500;
            margin: 28px 0 16px;
            color: $primary-dark;
            &__append {
                margin: 120px 0 0;
            }
        }
        &__subtitle {
            font-size: 18px;
            line-height: 23px;
            color: $primary-dark;
            font-weight: 500;
            margin-bottom: 16px;
        }
        &__text {
            font-size: 18px;
            line-height: 23px;
            color: $primary-dark;
            font-weight: 400;
            padding-bottom: 28px;
            &__list {
                display: list-item;
                margin-left: 26px;
            }
            &__dash {
                margin-left: 60px;
                display: block;
                &::before {
                    content: "-";
                    margin-right: 5px;
                }
            }
        }
    }
}

@include scale_from_to($tablet, $min-size) {
    .tc-wrapper {
        padding: 0 18px;
        margin: 0 auto 23px;

        &__append {
            &__text {
                margin: 16px 0 0;
            }
            &__link {
                font-size: 16px;
                line-height: 21px;
            }
        }

        &__header{
            &__title {
                font-size: 42px;
                line-height: 45px;
                min-height: 45px;
                -webkit-text-stroke: 1px $black;
                text-shadow: 2px 4px 0 $black;
            }
        }

        &__container {
            &__block{
                border-bottom: 2px solid $primary-dark;
            }
            &__title {
                font-size: 22px;
                line-height: 29px;
                margin: 22px 0 12px;
                &__append {
                    margin: 60px 0 0;
                }
            }
            &__subtitle {
                margin-bottom: 12px;
            }
            &__text {
                font-size: 16px;
                line-height: 21px;
            }
        }
    }
}

