@import './../../core.scss';

.speakers {
    &__title {
        margin: 44px 0 6px;
        @include title();
        text-align: center;
        color: $yellow;
        font-size: 68px;
        line-height: 70px;
        width: 100%;

    }

    &__subtitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: $primary-dark;
        text-align: center;
    }

    .persons {
        display: flex;
        align-items: center;
        justify-content: center;

        &__wrap {
            max-width: $desktop;
            justify-items: center;
            width: 100%;
        }
    }

    @media screen and (max-width: 880px) {
        &__title {
            -webkit-text-stroke: 1px $black;
        }
        &__title::after {
            display: none;
            content: attr(title);
            top: 0;
            white-space: pre-wrap;
        }
    }

    @media screen and (max-width: 768px) {
        &__subtitle {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }

    @media screen and (max-width: 548px) {
        &__title {
            margin: 26px 0 0;
            font-size: 36px;
            line-height: 48px;
            text-shadow: 3px 3px 0 #000;
        }
    }
}
