@import './../../core.scss';

.partners {
    display: grid;
    grid-template-rows: repeat(3, min-content);
    position: relative;

    max-width: 1350px;
    margin: 0 auto;

    &__block {
        margin: 24px 0px;
    }
    &__logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
    }
    &__title {
        font-weight: 700;
        font-size: 26px;
        line-height: 40px;
        color: $primary-dark;
        margin-bottom: 12px;
    }

    &__logo {
        background: transparent;
        height: 105px;

        display: flex;
        align-items: flex-start;
        justify-content: center;

        & img {
            width: inherit;
            height: inherit;
        }

        &:last-child {
            & img {
                width: 155px;
            }
        }
    }

    @media screen and (min-width: 768px) {
        &__title {
            margin-bottom: 16px;
        }
        &__logos {
            gap: 12px 24px;
        }
        &__logo {
            height: 126px;
            &:last-child {
                & img {
                    width: 186.75px;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) {
        padding: 0 10px;

        &__block {
            margin: 42px 0px;
        }
        &__title {
            font-size: 46px;
            line-height: 68px;
            margin-bottom: 24px;
        }
        &__logos {
            gap: 12px 42px;
        }
    }
}
