@import "./../../core.scss";

.header {
  position: relative;
  max-width: 1400px;
  height: 780px;
  margin: 0 auto;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: center;

  &__image {
    position: absolute;
    z-index: -1;
    width: 100%;
  }
  &__title {
    color: $red;
    font-size: 92px;
    line-height: 110px;
    margin: 0 auto;
    text-align: center;

    @include title();

    @media screen and (min-width: 768px) {
      white-space: nowrap;
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: $black;
  }

  &__heading {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    margin-bottom: 12px;
    margin-left: 15px;
  }

  &__actions {
    margin-left: 15px;
  }
}

@media screen and (max-width: 1440px) {
  .header {
    height: 620px;

    &__image {
      height: 620px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .header {
    height: 460px;

    &__title {
      font-size: 66px;
      line-height: 76px;
      margin: 0 0 6px;
      text-align: unset;
    }

    &__image {
      height: 460px;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 388px;

    &__heading {
      margin-left: 0;
    }

    &__title {
      margin: 0 20px;
      font-size: 66px;
      line-height: 76px;
      align-self: flex-start;

      &::after {
        content: attr(title);
        position: absolute;
        left: 0;
        top: 0;
        width: fit-content;
      }
    }
    &__subtitle {
      margin: 0 20px;
      align-self: flex-start;
    }
    &__image {
      height: 385px;
      width: inherit;
      right: -5%;
      transform: translateX(2%);
    }

    &__actions {
      padding: 0 16px;
      width: calc(100% - 32px);
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .header {
    height: 350px;
    &__title {
      margin: 0 20px;
      font-size: 36px;
      line-height: 48px;
      align-self: flex-start;

      &::after {
        content: attr(title);
        position: absolute;
        left: 0;
        top: 0;
        width: fit-content;
      }
    }
    &__subtitle {
      margin: 0 20px;
      font-size: 20px;
      line-height: 32px;
      align-self: flex-start;
    }
    &__image {
      height: 346px;
      width: inherit;
      right: -15%;
      transform: translateX(8%);
    }

    &__actions {
      padding: 0 16px;
      width: calc(100% - 32px);
      & button {
        width: 100%;
      }
    }
  }
}
