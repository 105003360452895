@import "./../../core.scss";
.notPromoting {
    position: relative;

    max-width: 1351px;
    margin: 62px auto;
    padding: 42px 0;
    background: $orange;
    border: 2px solid $black;
    border-radius: 12px;
    box-shadow: 8px 8px 0px $black;

    &__text {
        font-weight: 700;
        font-size: 46px;
        line-height: 65px;
        color: $primary-dark;
        max-width: 1140px;
        text-align: center;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1200px) {
    .notPromoting {
        padding: 16px;
        margin: 58px auto;
        &__text {
            font-size: 26px;
            line-height: 40px;
        }
    }
}


@media screen and (min-width: 768px) {
    .notPromoting {
        margin: 40px auto;
    }
}
